import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';


interface myData{
  success:boolean,
  message: string,
  message1: string,
  id_usuario: number,
  tipo_usuario: number
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private LoggedInStatus = false;
  private id_usuario;
  private tipo_usuario;

  constructor(private http: HttpClient) { }


  setLoggedIn(value: boolean, id_usuario, tipo_usuario){
   this.LoggedInStatus = value;
   this.id_usuario = id_usuario;
   this.tipo_usuario = tipo_usuario;
  }
  get getId(){
    return this.id_usuario
  }
  get getType(){
    return this.tipo_usuario
  }
  get isLoggedIn(){
    return this.LoggedInStatus
  }

  getUsersDetails(username, password){
    
return this.http.post<myData>('http://tasty.drmonkey.co/data/UsuariosQ.php',"comando=login&usuario="+username+"&contrasena="+password )
  }
  registerUserService(username, password, nickname){
    
return this.http.post<myData>('http://tasty.drmonkey.co/data/UsuariosQ.php',"comando=insert&usuario="+username+"&contrasena="+password+"&nickname="+nickname )
  }

}
