import { Component , OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {Router} from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  constructor(private Auth:AuthService, private router: Router) { }

  ngOnInit() {
  }
  loginUser(event){
    event.preventDefault()
    const target=event.target
    const username= target.querySelector('#username').value
    const password= target.querySelector('#password').value
    this.Auth.getUsersDetails(username,password).subscribe(data=> {
  if(data.success){
    this.Auth.setLoggedIn(true,data.id_usuario, data.tipo_usuario)
    if(data.tipo_usuario==7){
      this.router.navigate(['/proveedores/hilazaredashboard'])
    }else {
     this.router.navigate(['dashboard'])
    } 
  }else{
    window.alert(data.message)
  }
})
    
  }

 }
