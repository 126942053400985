interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems2: NavData[] = [
  {
    name: 'Productos',
    url: '/proveedores/productos',
    icon: 'icon-speedometer'
  },
  {
    name: 'Empleados',
    url: '/proveedores/empleados',
    icon: 'icon-speedometer'
  },
  {
    name: 'Insumos',
    url: '/proveedores/insumos',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Cajas',
    url: '/proveedores/cajas',
    icon: 'icon-speedometer'
  },
  {
    name: 'Compras',
    url: '/proveedores/compras',
    icon: 'icon-speedometer'
  },
  {
    name: 'Ventas',
    url: '/proveedores/ventas',
    icon: 'icon-speedometer'
  },
  {
    name: 'Nominas',
    url: '/proveedores/nominas',
    icon: 'icon-speedometer'
  },
  {
    name: 'Prestamos',
    url: '/proveedores/prestamos',
    icon: 'icon-speedometer'
  }
  
  
];
